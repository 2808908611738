import { Injectable } from '@angular/core';
import { SelectOption } from '@evonik/evo';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class LanguageService {

    languages: SelectOption[] = [];

    locales: { [lang: string]: string } = {};

    $currentLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(this.currentLanguage);

    $currentLocale: BehaviorSubject<string> = new BehaviorSubject<string>(this.locale);

    get currentLanguageChange(): Observable<string> {
        return this.$currentLanguage.asObservable();
    }

    get currentLocale(): Observable<string> {
        return this.$currentLocale.asObservable();
    }

    constructor(
        private translate: TranslateService
    ) {
        this.languages = [
            { value: 'de', label: this.translate.instant('German') },
            { value: 'en', label: this.translate.instant('English') },
            { value: 'fr', label: this.translate.instant('French') },
            { value: 'zh', label: this.translate.instant('Chinese') },
            { value: 'hu', label: this.translate.instant('Hungarian') },
            { value: 'ja', label: this.translate.instant('Japanese') },
            { value: 'pt', label: this.translate.instant('Portuguese') },
            { value: 'ru', label: this.translate.instant('Russian') },
            { value: 'es', label: this.translate.instant('Spanish') },
            { value: 'it', label: this.translate.instant('Italian') },
            { value: 'vi', label: this.translate.instant('Vietnamese') },
            { value: 'th', label: this.translate.instant('Thai') },
            { value: 'tr', label: this.translate.instant('Turkey') },
            { value: 'ko', label: this.translate.instant('Korean') },
        ]

        this.locales =
        {
            de: 'de-DE',
            en: 'en-EN',
            fr: 'fr-FR',
            zh: 'zh-CN',
            hu: 'hu-HU',
            ja: 'ja-JP',
            pt: 'pt-PT',
            ru: 'ru-RU',
            es: 'es-ES',
            it: 'it-IT',
            vi: 'vi-VN',
            th: 'th-TH',
            tr: 'tr-TR',
            ko: 'ko-KR'
        }

        this.$currentLocale.next(this.locales[this.currentLanguage]);
    }

    get currentLanguage() {
        const localValue = localStorage.getItem('ana3-language');
        return localValue ? localValue : 'en';
    }

    get locale(): string {
        return this.locales[this.currentLanguage];
    }

    set currentLanguage(language: string) {
        this.translate.use(language)
        .subscribe({       
            next: () => {},
            error: () => {
                language = "en";
                this.translate.use(language);
            }
        })
        .add(() => {        
            localStorage.setItem('ana3-language', language);   
            this.$currentLanguage.next(language);
            this.$currentLocale.next(this.locales[language]);
        });
    }
}
