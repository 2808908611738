<evo-dialog>
    <div class="lock-screen" content>
        <h1 class="lock-screen__headline">{{'AMINONIR® Advanced 4' | translate}}</h1>
        <h2 class="lock-screen__subheadline">{{'Advanced analyses of your raw materials' | translate}}</h2>

        <div class="lock-screen__inner">
            <app-authorization-error>
            </app-authorization-error>
        </div>

        <img class="lock-screen__evonik-logo" src="assets/img/evonik-logo.svg"
            alt="Evonik - Leading Beyond Chemistry" />
    </div>
</evo-dialog>
