import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, ReplaySubject, catchError, map, of } from 'rxjs';

// @dynamic
export function configInitializerFn(configService: ConfigService) {
    return () => {
        return new Observable((subscriber) => {
            configService.loadAppConfig()
                .subscribe((success) => {
                    if (success) {
                        subscriber.complete()
                    } else {
                        subscriber.error("Error loading app config!")
                    }
                });
        })
    }
}

@Injectable({
    providedIn: 'root'
})
export class ConfigService {

    private path = '/assets/config.json';
    private $appConfig: ReplaySubject<Map<string, any>> = new ReplaySubject();
    private _appConfig: Map<string, any> = new Map();

    get appConfigLoaded(): Observable<Map<string, any>> {
        return this.$appConfig.asObservable();
    }

    get appConfig(): Map<string, any> {
        return this._appConfig;
    }

    constructor(private http: HttpClient) {
    }

    loadAppConfig(): Observable<boolean> {
        return this.http.get<any>(this.path).pipe(
            map((res) => {
                this._appConfig = new Map(Object.entries(res));
                this.$appConfig.next(this._appConfig);
                return true;
            }),
            catchError((error) => {
                console.error(error);
                return of(false);
            })
        );
    }
}
