<evo-dialog>
    
    <div *ngFor="let message of sanitizedInfoscreens; let i = index">
    
        <ng-container *ngIf="currentOpenedMessagesIndex === i">      

            <evo-icon-button [iconName]="iconNames.Cross" [iconSize]="iconSizes.normal" (click)="close()" class="float-right" header></evo-icon-button>

            <div content>
                <evo-spacer [height]="50" class="isDesktop"></evo-spacer> 
                <evo-spacer [height]="10" class="isTablet isMobile"></evo-spacer> 

                <div [innerHTML]="message" class="nav__label paragraphM"></div>

                <evo-spacer [height]="100" class="isDesktop" *ngIf="!iFramePresent"></evo-spacer>
                <evo-spacer [height]="50" class="isDesktop" *ngIf="iFramePresent"></evo-spacer>  
                <evo-spacer [height]="20" class="isTablet isMobile"></evo-spacer> 
            </div>
            
            <div footer>  
                <div class="flex-container">
                    <evo-button (click)="previous()" [iconPosition]="ButtonIconPosition.Left" [icon]="iconNames.ArrowLeft" class="flex-container info-screen-button" *ngIf="i !== 0 && i <= infoScreens.length-1">   
                        Previous message
                    </evo-button>   

                    <!-- show when prev button hidden to reserve space -->
                    <div *ngIf="i === 0"></div>      

                    <evo-checkbox [control]="markAsReadControl" [label]="'Mark as read'">
                    </evo-checkbox>

                    <evo-button (click)="next()" [iconPosition]="ButtonIconPosition.Right" [icon]="iconNames.ArrowRight" class="flex-container info-screen-button" *ngIf="i < infoScreens.length-1"> 
                        Next message
                    </evo-button>  

                    <evo-button (click)="closeOrUpdate()" [iconPosition]="ButtonIconPosition.Right" [icon]="iconNames.Check" class="flex-container info-screen-button" *ngIf="i === infoScreens.length-1"> 
                        Close info screen
                    </evo-button>   
                </div> 
            </div>  
        </ng-container>
    </div>

</evo-dialog>