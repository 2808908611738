import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { InfoScreenDto } from "../models/info-screen.model";
import { ConfigService } from "./config.service";

@Injectable({ providedIn: 'root' })
export class InfoScreenService {
    private readonly apiPath = 'dotnet-api/Infoscreen';

    constructor(
        private readonly http: HttpClient,
        private readonly config: ConfigService) { }

    private getUrl(): string {
        return this.config.appConfig.get('apiBaseUrl');
    }

    getAll(): Observable<InfoScreenDto[]> {
        return this.http.get<InfoScreenDto[]>(`${this.getUrl()}/${this.apiPath}`);
    }

    update(messageKey: string): Observable<boolean> {

        if (!messageKey) {
            throw new Error('Info Screen needs an key to update');
        }

        return this.http.put<boolean>(`${this.getUrl()}/${this.apiPath}?messageKey=${messageKey}`, []);
    }
}