import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { MasterData, OriginProvinces, OriginSubregions } from '../models/masterdata.model';
import { ConfigService } from './config.service';
import { LanguageService } from './language.service';

@Injectable({
    providedIn: 'root'
})
export class MasterdataService {

    emptyMaster = { instruments: [], materials: [], originCountries: [] };

    private baseUrl = "/s-ana3";

    private $masterData: BehaviorSubject<MasterData> = new BehaviorSubject<MasterData>(this.emptyMaster);

    private $loading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    get masterData(): Observable<MasterData> {
        return this.$masterData.asObservable();
    }

    get loading(): Observable<boolean> {
        return this.$loading.asObservable();
    }

    constructor(private http: HttpClient, private config: ConfigService, languageService: LanguageService) {
        this.baseUrl = this.config.appConfig.get('apiBaseUrl');
        languageService.currentLanguageChange.subscribe(lang => {
            this.loadMasterData(lang)
        })
    }

    private loadMasterData(lang: string): void {
        this.$loading.next(true);

        const httpOptions = {
            headers: new HttpHeaders({
                'Accept-Language': lang,
            })
        };

        this.http.get<MasterData>(`${this.baseUrl}/api/masterdata`, httpOptions).pipe(
            catchError((error) => {
                this.$loading.next(false);
                return of(this.emptyMaster);
            })
        ).subscribe(masterData => {
            this.$loading.next(false);
            this.$masterData.next(masterData);
        });
    }

    getProvincesBySubregionId(id: string): Observable<OriginProvinces> {
        return this.http.get<OriginProvinces>(`${this.baseUrl}/api/masterdata/subregions/${id}/provinces`);
    }

    getSubregionsByCountryId(id: string): Observable<OriginSubregions> {
        return this.http.get<OriginSubregions>(`${this.baseUrl}/api/masterdata/countries/${id}/subregions`);
    }
}
