import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class WelcomeScreenService {

    constructor() { }

    get show(): boolean {
        const localValue = localStorage.getItem('ana3-showIntro');
        return localValue ? localValue === 'true' : true;
    }

    set show(showIntro: boolean) {
        localStorage.setItem('ana3-showIntro', `${showIntro}`);
    }
}
