import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Colors } from '@evonik/evo';
import { MasterData } from '../../models/masterdata.model';

@Component({
    selector: 'app-instruments',
    templateUrl: './instruments.component.html',
    styleUrls: ['./instruments.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstrumentsComponent implements OnInit {

    @Input()
    masterData: MasterData | null = null;

    @Input()
    loadingMasterData = false;

    colors = Colors;

    constructor() { }

    ngOnInit(): void {
    }

}
