<evo-placeholder [icon]="iconNames.Lock" [headline]="'auth.error.label.notAuthorized' | translate"></evo-placeholder>

<evo-spacer [height]="60"></evo-spacer>

<div class="auth-error__text">
    <p>{{ 'auth.error.headline' | translate }}</p>
    <p>{{ 'auth.error.thanks' | translate }}</p>
    <p>{{ 'auth.error.getInTouch' | translate }}</p>

    <evo-button (click)="openMyAmino()">{{
        'auth.error.visitMyAmino' | translate
        }}</evo-button>
</div>
