import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ButtonIconPosition, IconName, IconSize } from '@evonik/evo';
import { DomSanitizer } from '@angular/platform-browser';
import { InfoScreenDto } from '../../models/info-screen.model';
import { InfoScreenService } from '../../services/info-screen.service';

@Component({
  selector: 'app-info-screen',
  templateUrl: './info-screen.component.html',
  styleUrls: ['./info-screen.component.scss']
})
export class InfoScreenComponent implements OnInit {

  @Input() set setInfoScreens(infoScreens: Array<InfoScreenDto>) {
    this.infoScreens = infoScreens;
  }

  @Output() closeInfoScreen: EventEmitter<boolean> = new EventEmitter<boolean>();

  markAsReadControl = new FormControl();

  iconNames = IconName;
  ButtonIconPosition = ButtonIconPosition;
  iconSizes = IconSize;
  markAsRead = new FormControl;
  currentOpenedMessagesIndex: number = 0;

  infoScreens: Array<InfoScreenDto> = [];
  markAsReadIndex: Array<number> = [];
  sanitizedInfoscreens: Array<any> = [];
  iFramePresent: boolean = false;

  constructor(private readonly infoScreenService: InfoScreenService, private readonly sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.infoScreens.forEach((infoScreen: any) => {
      if (infoScreen.messageText.includes("iframe")) {
        this.iFramePresent = true;
      }
      else {
        this.iFramePresent = false;
      }
      this.sanitizedInfoscreens.push(this.sanitizer.bypassSecurityTrustHtml(infoScreen.messageText));
    })

    this.markAsReadControl.valueChanges.subscribe((value) => {
      if (value) {
        this.markAsReadIndex.push(this.currentOpenedMessagesIndex);
      }
      else {
        this.markAsReadIndex = this.markAsReadIndex.filter(index => index != this.currentOpenedMessagesIndex);
      }
    });

  }

  setMarkAsReadControl(currentOpenedMessagesIndex: number) {
    this.markAsReadControl.setValue(this.markAsReadIndex.includes(currentOpenedMessagesIndex));
  }

  previous() {
    this.currentOpenedMessagesIndex -= 1;
    this.setMarkAsReadControl(this.currentOpenedMessagesIndex);
  }

  next() {
    this.currentOpenedMessagesIndex += 1;
    this.setMarkAsReadControl(this.currentOpenedMessagesIndex);
  }

  close() {
    this.closeInfoScreen.emit(true);
  }

  closeOrUpdate() {
    if (this.markAsReadIndex.length > 0) {
      const bar = new Promise<boolean>((resolve, reject) => {
        this.markAsReadIndex.forEach((array, index) => {

          const infoScreen = this.infoScreens.at(array);

          if (infoScreen) {
            this.infoScreenService.update(infoScreen.messageKey).subscribe(
              {
                next: (infoScreen) => {
                  if (index === this.markAsReadIndex.length - 1) resolve(true);
                },
                error: (e) => {
                  console.log('error updating info screen')
                },
              });
          }
        });
      });

      bar.then(() => {
        this.close();
      });

    }
    else {
      this.close();
    }
  }
}
