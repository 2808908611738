import { Component, Input } from '@angular/core';
import { Colors, IconName } from '@evonik/evo';
import { ConfigService } from 'src/app/core/services/config.service';

@Component({
    selector: 'app-authorization-error',
    templateUrl: './authorization-error.component.html',
    styleUrls: ['./authorization-error.component.scss'],
})
export class AuthorizationErrorComponent {
    colors = Colors;
    iconNames = IconName;

    constructor(private configService: ConfigService) { }

    logout(): void {
        window.location.href = `${this.configService.appConfig.get('apiBaseUrl')}/saml/logout`;
    }

    openMyAmino() {
        window.location.href = 'https://myamino.evonik.com';
    }
}
