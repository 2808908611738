@if (instrument) {
  <div class="instrument">
    <span class="purple-text">
      <span class="glyphicon glyphicon-user"></span>
      {{instrument.macCustomer}}
      ({{instrument.macNo}})
    </span>
    <br>
      <span class="color-purple">
        <span class="glyphicon glyphicon-user"></span>
        {{instrument.labCustomer}}
      </span>
      <br>
        <strong>
          {{instrument.instrumentSerialnumber}} {{instrument.instrumentType}} {{instrument.name}}
          @if (instrument.localName) {
            (<i>{{instrument.localName}}</i>)
          }
        </strong>
      </div>
    }
