import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthorizedGuard } from './core/guards/authorized.guard';

const routes: Routes = [
    { path: '', redirectTo: 'upload-samples', pathMatch: 'full' },
    {
        path: 'upload-samples',
        loadChildren: () => import('./upload-samples/upload-samples.module')
            .then((m) => m.UploadSamplesModule),
        canActivate: [AuthorizedGuard],
    },
    {
        path: 'upload-standard',
        loadChildren: () => import('./upload-standard/upload-standard.module')
            .then((m) => m.UploadStandardModule),
        canActivate: [AuthorizedGuard],
    },
    {
        path: 'unauthorized',
        loadChildren: () => import('./unauthorized/unauthorized.module')
            .then((m) => m.UnauthorizedModule),
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule]
})
export class AppRoutingModule { }
