import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from './core/services/user.service';
import { MasterdataService } from '../app/core/services/masterdata.service';
import { LanguageService } from './core/services/language.service';
import { RouterOutlet } from '@angular/router';
import { fadeAnimation } from './core/animations';
import { combineLatest } from 'rxjs';
import { InfoScreenService } from './core/services/info-screen.service';
import { InfoScreenDto } from './core/models/info-screen.model';


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        fadeAnimation
    ]
})
export class AppComponent implements OnInit {

    loadingMasterData = false;

    startApp = false;

    showInfoScreen: boolean = false;
    infoScreens: Array<InfoScreenDto> = [];

    constructor(
        private readonly translateService: TranslateService,
        private readonly authService: UserService,
        private readonly languageService: LanguageService,
        private readonly masterDataService: MasterdataService,
        private readonly infoScreenService: InfoScreenService) {

    }

    ngOnInit(): void {
        this.translateService.setDefaultLang('en');
        this.translateService.addLangs(['en', 'de']);
        this.translateService.use(this.languageService.currentLanguage);

        this.masterDataService.loading.subscribe(loading => this.loadingMasterData = loading);

        this.infoScreenService.getAll().subscribe({
            next: (infoScreens) => {
                this.infoScreens = infoScreens ? infoScreens.filter(infoScreen => !infoScreen.isHidable) : [];
                if (this.infoScreens.length > 0) {
                    this.showInfoScreen = true;
                }
            },
            error: (error) => {
                console.error(error);
            }
        });
    }
    title = 'ans-ana3-fe';

    get masterData() {
        return this.masterDataService.masterData;
    }

    start() {
        this.startApp = true;
    }

    prepareRoute(outlet: RouterOutlet) {
        return outlet?.activatedRouteData['animation'];
    }

    isAuthorized(): boolean | undefined {
        return this.authService.hasANA3Auth();
    }

    setCloseInfoScreen(status: boolean) {
        this.showInfoScreen = !status;
    }
}
