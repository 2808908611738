import { Component, Input, OnInit } from '@angular/core';
import { ButtonIconPosition, ButtonSkin, ButtonTextAlignment, Colors, FormFieldDecoration, IconName, IconSize, Positions, SelectOption } from '@evonik/evo';
import { User, UserAuthorities } from '../../models/user.model';
import { UserService } from '../../services/user.service';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormControl } from '@angular/forms';
import { LanguageService } from '../../services/language.service';
import { WelcomeScreenService } from '../../services/welcome-screen.service';
import { ConfigService } from '../../services/config.service';
import { OAuthService } from 'angular-oauth2-oidc';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

    @Input() showContent = true;

    iconSizes = IconSize;
    colors = Colors;
    positions = Positions;
    userName: string = '';
    showProfileMenu: boolean = false;
    iconNames = IconName;
    buttonSkins = ButtonSkin;
    iconPositions = ButtonIconPosition;
    textAlignments = ButtonTextAlignment;
    formFieldDecoration = FormFieldDecoration;

    currentUser: User | undefined;

    UserAuthorities = UserAuthorities;

    selectedLanguage = new UntypedFormControl('en');
    showIntroScreenControl = new UntypedFormControl(true);

    anaCustomViewTypeUrl = "#";

    constructor(
        private authService: UserService,
        public languageService: LanguageService,
        public welcomeScreenService: WelcomeScreenService,
        private oAuthService: OAuthService, 
        private configService: ConfigService
    ) {
        this.currentUser = this.authService.currentUser;
        this.userName = `${this.currentUser?.firstName} ${this.currentUser?.lastName}`

        this.showIntroScreenControl.setValue(welcomeScreenService.show);

        this.showIntroScreenControl.valueChanges.subscribe(value => {
            this.welcomeScreenService.show = value;
        })

        this.selectedLanguage.setValue(languageService.currentLanguage);

        this.selectedLanguage.valueChanges.subscribe(value => {
            this.languageService.currentLanguage = value;
        })

        this.anaCustomViewTypeUrl = this.configService.appConfig.get('anaCustomViewTypeUrl');
    }

    toggleProfileMenu(): void {
        this.showProfileMenu = !this.showProfileMenu;
    }

    logout() {
        this.oAuthService.logOut();
    }
}
