import { Pipe, PipeTransform } from '@angular/core';
import { User, UserAuthorities } from '../../models/user.model';

@Pipe({
    name: 'hasAuthority'
})
export class HasAuthorityPipe implements PipeTransform {

    transform(user: User, userAuthorities: UserAuthorities): boolean {
        return !!user
            && !!user.authorities
            && !!userAuthorities
            && user.authorities.length > 0
            && user.authorities.some(item => userAuthorities === item);
    }

}
