import { animate, animateChild, group, query, style, transition, trigger } from "@angular/animations";

export const fadeAnimation = trigger('fadeAnimation', [
    // route 'enter' transition
    transition(':enter', [

        // css styles at start of transition
        style({ opacity: 0 }),

        // animation and styles at end of transition
        animate('.7s', style({ opacity: 1 }))
    ]),
]);

export const InOutAnimation =
    trigger('inOutAnimation', [
        transition(':enter', [
            style({ transform: 'translateY(-100%)' }),
            animate('200ms ease-in', style({ transform: 'translateY(0%)' }))
        ]),
        transition(':leave', [
            animate('200ms ease-in', style({ transform: 'translateY(-100%)' }))
        ])
    ]);
