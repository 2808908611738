import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { FileUploadModule } from '@iplab/ngx-file-upload';

import { registerLocaleData } from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeTh from '@angular/common/locales/th';
import localeVi from '@angular/common/locales/vi';
import localeZh from '@angular/common/locales/zh';
import localeTr from '@angular/common/locales/tr';
import localeHu from '@angular/common/locales/hu';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
    EvoModule
} from '@evonik/evo';
import { AuthConfig, OAuthModule, OAuthModuleConfig, OAuthService } from 'angular-oauth2-oidc';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { UserService } from './core/services/user.service';
import { ConfigService, configInitializerFn } from './core/services/config.service';
import { LanguageService } from './core/services/language.service';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LanguageInterceptor } from './core/interceptors/langauge';

registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localeZh);
registerLocaleData(localeJa);
registerLocaleData(localePt);
registerLocaleData(localeRu);
registerLocaleData(localeEs);
registerLocaleData(localeIt);
registerLocaleData(localeVi);
registerLocaleData(localeHu);
registerLocaleData(localeTr);

registerLocaleData(localeTh);
registerLocaleData(localeKo);

export const authCodeFlowConfig: AuthConfig = {
    // URL of the SPA to redirect the user to after login
    redirectUri: window.location.origin,
    requireHttps: false,
    strictDiscoveryDocumentValidation: false,
    responseType: 'code',
    showDebugInformation: true,
};

/**
 * Initialize the authentication flow on startup
 * @param oAuthService Used to start the authentication flow
 * @param configService Here we get the information to config the authentication
 * @param moduleConfig We need the module config to set the allowed backend urls dynamically
 * @returns a promise which completes after authentication
 */
export function initializeAuthorization(oAuthService: OAuthService, configService: ConfigService, moduleConfig: OAuthModuleConfig, userService: UserService, router: Router) {
    return () => {
        return new Observable((subscriber) => {
            // Wait to load the configuration from the config.json
            configService.appConfigLoaded.subscribe(config => {
                // Then put the values in the authCodeFlowConfig
                authCodeFlowConfig.issuer = config.get('authorizationIssuer');
                authCodeFlowConfig.tokenEndpoint = config.get('tokenEndpoint');
                // The SPA's id. The SPA is registerd with this id at the auth-server
                authCodeFlowConfig.clientId = config.get('authorizationClientId');
                authCodeFlowConfig.showDebugInformation = config.get('authorizationShowDebugInformation');
                // set the scope for the permissions the client should request
                // The first four are defined by OIDC.
                // Important: Request offline_access to get a refresh token
                // The api scope is a usecase specific one
                // 'openid profile email offline_access api://88d84d5e-e735-440b-86ca-1a86549c0d48/AccessData'
                authCodeFlowConfig.scope = config.get('authorizationScope');
                authCodeFlowConfig.disableIdTokenTimer = true;
                oAuthService.configure(authCodeFlowConfig);
                oAuthService.setupAutomaticSilentRefresh();

                // set the urls where the interceptor adds the access token. Most often the backend in our case.
                const allowedUrls: any = config.get('authorizationAllowedUrls');
                moduleConfig.resourceServer.allowedUrls = allowedUrls ? [...allowedUrls] : [];
                // Initialize authentication
                oAuthService
                    .loadDiscoveryDocument(/* { your LoginOptions }*/) // checks to see if the current url contains id token and access token
                    .then(hasReceivedTokens => {
                        oAuthService.tokenEndpoint = config.get('tokenEndpoint');
                        oAuthService.tryLogin().then(async () => {
                            // may want to check if you were previously authenticated
                            if (oAuthService.hasValidAccessToken() && oAuthService.hasValidIdToken()) {
                                try {
                                    await userService.loadUser(config.get('apiBaseUrl'));
                                    subscriber.complete();
                                    return Promise.resolve();
                                } catch (error) {
                                    // forward to unauthorized if there was a problem while loading the current user
                                    router.navigate(['unauthorized']);
                                    console.error(error);
                                    subscriber.complete();
                                    return Promise.resolve();
                                }
                            } else {
                                // to safe guard this from progressing through the calling promise,
                                // resolve it when it directed to the sign up page
                                return new Promise(resolve => {
                                    oAuthService.initLoginFlow();
                                    // example if you are using explicit flow
                                    window.addEventListener('unload', () => {
                                        resolve(true);
                                    });
                                });
                            }
                        });

                    });
            });
        });
    }
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        FileUploadModule,
        ReactiveFormsModule,
        CoreModule,
        EvoModule,
        OAuthModule.forRoot({
            resourceServer: {
                allowedUrls: [],
                sendAccessToken: true
            }
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
    ],
    providers: [
        {
            provide: LOCALE_ID,
            deps: [LanguageService],      //some service handling global settings
            useFactory: (languageService: LanguageService) => languageService.locale  //returns locale string
        },
        OAuthService,
        UserService,
        {
            provide: APP_INITIALIZER,
            multi: true,
            deps: [ConfigService],
            useFactory: configInitializerFn
        },
        {
            provide: APP_INITIALIZER,
            useFactory: initializeAuthorization,
            deps: [
                OAuthService, ConfigService, OAuthModuleConfig, UserService, Router
            ],
            multi: true
        },
        { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}