<h4 style="padding-left: 0.5rem"> {{ 'Assigned instruments' | translate}}</h4>

@if (masterData; as data) {
  <div>
    <ul>
      @for (instrument of data.instruments; track instrument) {
        <li>
          <app-instrument [instrument]="instrument"></app-instrument>
        </li>
      }
    </ul>
    @if (loadingMasterData) {
      <p class="spinner" [style.color]="colors.baseEvonik">
        <evo-spinner [size]="30"></evo-spinner>
      </p>
    }
    @if (!loadingMasterData && data.instruments.length === 0) {
      <p>
        {{ 'No instruments assigned' | translate}}
      </p>
    }
  </div>
}
