import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User, UserAuthorities } from '../models/user.model';
import { map } from 'rxjs/operators';
import { firstValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    public currentUser: User | undefined;

    constructor(private http: HttpClient) {
    }

    hasANA3Auth(): boolean | undefined {
        return this.currentUser?.authorities?.includes(UserAuthorities.ANA3);
    }

    hasSpectraAuth(): boolean | undefined {
        return this.currentUser?.authorities?.includes(UserAuthorities.ANA3_UPLOAD_SPECTRA);
    }

    hasStdAuth(): boolean | undefined {
        return this.currentUser?.authorities?.includes(UserAuthorities.ANA3_UPLOAD_STD);
    }

    hasANA4AllowResult(): boolean | undefined {
        return this.currentUser?.authorities?.includes(UserAuthorities.ANA3_ALLOW_RESULT);
    }

    async loadUser(baseUrl: string): Promise<void> {
        this.currentUser = await firstValueFrom(this.http.get<User>(`${baseUrl}/api/user`));
    }
}
