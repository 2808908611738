import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LanguageService } from '../services/language.service';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

    language = 'en-US';

    constructor(languageService: LanguageService) {
        languageService.currentLanguageChange.subscribe(lang => {
            this.language = lang;
        })
    }
    
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const clonedRequest = req.clone({
            setHeaders: {
                'Accept-Language': this.language // You can dynamically set the language here
            }
        });

        return next.handle(clonedRequest);
    }
}