<evo-app-header [backgroundColor]="colors.baseEvonik" [contentLimitation]="'100%'">
  <a [routerLink]="['/']" class="app-header__logo" logo>
    <img src="assets/img/ana3-logo.svg" style="height: 60px;" />
  </a>

  @if (showContent && currentUser) {
    <nav class="app-header__nav" menu>
      @if (currentUser | hasAuthority: UserAuthorities.ANA3_UPLOAD_SPECTRA) {
        <a class="app-header__nav-item"
          [routerLink]="['/upload-samples']" [routerLinkActive]="'app-header__nav-item--active'">
          <evo-icon [name]="iconNames.UploadCloud"></evo-icon>
          <p class="nav__label paragraphXS textEllipsis">
            {{ 'Upload samples' | translate }}
          </p>
        </a>
      } 
        
    @if (currentUser | hasAuthority: UserAuthorities.ANA3_UPLOAD_STD) {
      <a class="app-header__nav-item"
        [routerLink]="['/upload-standard']" [routerLinkActive]="'app-header__nav-item--active'">
        <evo-icon [name]="iconNames.Certificate"></evo-icon>
        <p class="nav__label paragraphXS textEllipsis">
          {{ 'Standardization & Validation' | translate }}
        </p>
      </a>
    }
  </nav>
}

@if (showContent) {
  <div class="app-header__profile" meta>
    <div class="app-header__profile-data">
      <div class="app-header__profile-text" (click)="toggleProfileMenu()">
        <p class="labelS">{{ userName }}</p>
      </div>
      <button class="app-header__profile-btn" (click)="toggleProfileMenu()">
        <evo-icon [name]="iconNames.UserRound" [size]="iconSizes.big">
        </evo-icon>
      </button>
    </div>
    @if (showProfileMenu) {
      <div class="app-header__profile-panel">
        <div class="app-header__profile-info">
          <evo-icon class="app-header__profile-icon" [name]="iconNames.UserRound" [size]="iconSizes.mega">
          </evo-icon>
          <div>
            <p class="labelS">{{ userName }}</p>
          </div>
        </div>
        <div class="profile-controls">
          <div>
            <evo-form-field label="{{ 'Language' | translate}}" [control]="selectedLanguage">
              <evo-select [showSearch]="true" [options]="languageService.languages"></evo-select>
            </evo-form-field>
          </div>
          <div>
            <evo-form-field style="padding-left: 5px;" [control]="showIntroScreenControl" [decoration]="formFieldDecoration.Outline">
              <evo-checkbox style="height: 45px;
              align-items: center;
              display: flex;" label="{{ 'Show intro screen' | translate }}">
              </evo-checkbox>
            </evo-form-field>
          </div>
          <div>
            <a [href]="anaCustomViewTypeUrl" target="_blank">
              <evo-button [icon]="iconNames.Gears" [iconPosition]="iconPositions.Left"
              [textAlignment]="textAlignments.Left" [skin]="buttonSkins.Decent" [width]="'100%'">
              {{ 'Configure ANA4 Result Display' | translate }}
              </evo-button>
            </a>
          </div>
          <div>
          <evo-button [icon]="iconNames.Logout" (click)="logout()" [iconPosition]="iconPositions.Left"
            [textAlignment]="textAlignments.Left" [skin]="buttonSkins.Decent" [width]="'100%'">
            {{ 'Logout' | translate }}
          </evo-button>
        </div>
        </div>
        <evo-spacer [height]="15"></evo-spacer>
      </div>
    }
  </div>
}
</evo-app-header>

@if (showProfileMenu) {
  <div (click)="toggleProfileMenu()" class="app-header__overlay"></div>
}