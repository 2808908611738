import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
    providedIn: 'root'
})
export class AuthorizedGuard implements CanActivate {

    constructor(private authService: UserService, private router: Router) {

    }

    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        const currentUser = this.authService.currentUser
        const path = route.routeConfig?.path;

        if (!currentUser || !this.authService.hasANA3Auth() ||
            (!this.authService.hasSpectraAuth() && !this.authService.hasStdAuth())) {
            this.router.navigate(['unauthorized']);
            return false;
        }

        if (path && path === 'upload-standard' && this.authService.hasSpectraAuth() && !this.authService.hasStdAuth()) {
            this.router.navigate(['upload-samples']);
            return true;
        }

        if (path && path === 'upload-samples' && !this.authService.hasSpectraAuth() && this.authService.hasStdAuth()) {
            this.router.navigate(['upload-standard']);
            return true;
        }

        return true;
    }

}
