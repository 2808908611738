import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { Instrument } from '../../models/masterdata.model';

@Component({
    selector: 'app-instrument',
    templateUrl: './instrument.component.html',
    styleUrls: ['./instrument.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class InstrumentComponent implements OnInit {

    @Input()
    public instrument: Instrument | null = null;

    constructor() { }

    ngOnInit(): void {
    }

}
