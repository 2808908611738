@if (isAuthorized()) {
  <div class="app-layout">
    @if (showInfoScreen && !startApp) {
      <app-header [showContent]="false"></app-header>
      <app-info-screen [setInfoScreens]="infoScreens" (closeInfoScreen)="setCloseInfoScreen($event)"></app-info-screen>
    } @else {
      <app-header [showContent]="true"></app-header>
        <div style="    text-align: center;
        background-color: rgb(250, 235, 215);
        padding: 0.3rem; display:none">Due to maintenance reasons AMINONIR® Advanced will not be available on February 11, 2023 from 7
      am to 10 am german time (UTC+1/CET).</div>
      <div class="app-content">
        <div class="layout">
          <div class="layout__col layout__col--small side-col">
            <aside class="wrapper">
              <div class="wrapper__sidebar sidebar">
                <div class="sidebar__main-content">
                  <evo-spacer [height]="30"></evo-spacer>
                  <app-instruments [masterData]="masterData | async"
                  [loadingMasterData]="loadingMasterData"></app-instruments>
                </div>
              </div>
            </aside>
          </div>
          <div class="layout__col layout__col--large main-col">
            <evo-spacer [height]="30"></evo-spacer>
            <div style="display: block" [@fadeAnimation]="prepareRoute(outlet)">
              <router-outlet #outlet="outlet"></router-outlet>
            </div>
          </div>
        </div>
      </div>
    }
    <ng-template #app>
      <app-header [showContent]="true"></app-header>
        <div style="    text-align: center;
        background-color: rgb(250, 235, 215);
        padding: 0.3rem; display:none">Due to maintenance reasons AMINONIR® Advanced will not be available on February 11, 2023 from 7
      am to 10 am german time (UTC+1/CET).</div>
      <div class="app-content">
        <div class="layout">
          <div class="layout__col layout__col--small side-col">
            <aside class="wrapper">
              <div class="wrapper__sidebar sidebar">
                <div class="sidebar__main-content">
                  <evo-spacer [height]="30"></evo-spacer>
                  <app-instruments [masterData]="masterData | async"
                  [loadingMasterData]="loadingMasterData"></app-instruments>
                </div>
              </div>
            </aside>
          </div>
          <div class="layout__col layout__col--large main-col">
            <evo-spacer [height]="30"></evo-spacer>
            <div style="display: block" [@fadeAnimation]="prepareRoute(outlet)">
              <router-outlet #outlet="outlet"></router-outlet>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
}

<footer>
  <div class="footer-left" lang="en">
    AMINONIR® Advanced <span id="app-version">4</span> | © 2022 by Evonik Operations GmbH | Nutrition &amp; Care
  </div>
  <div class="footer-right">
    <nav>
      <ul>
        <li><a lang="en" title="Company information" target="_blank"
          href="http://animal-nutrition.evonik.com/product/feed-additives/en/footer-en/pages/company-information.aspx">Company
        information</a></li>
        <li><a lang="en" title="Legal notice" target="_blank"
          href="http://animal-nutrition.evonik.com/product/feed-additives/en/footer-en/pages/legal-notice.aspx">Legal
        notice</a></li>
        <li><a lang="en" title="Privacy policy" target="_blank"
          href="http://animal-nutrition.evonik.com/product/feed-additives/en/footer-en/pages/privacy-policy.aspx">Privacy
        policy</a></li>
      </ul>
    </nav>
  </div>
</footer>



@if (!isAuthorized()) {
  <app-lock-screen>
  </app-lock-screen>
}
